.aboutpage-works {
	font-family: var(--tertiary-font);
}

.about-logo-container {
	display: flex;
	justify-content: left;
	padding-top: 25px;
}

.about-logo {
	display: flex;
	position: fixed;
	border: 1px solid white;
	border-radius: 50%;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
	top: 4vh;
}

.about-container {
	display: flex;
	flex-direction: column;
	height: 100%;
	margin: 0;
	margin-top: 120px;
}

.about-main {
	display: flex;
	padding-bottom: 30px;
}
.about-header {
	/* flex-basis: 900px; */
	width: 100%;
}

.about-title {
	width: 100% !important;
}

.about-subtitle {
	width: 80%;
}

.about-right-side {
	display: flex;
	flex-direction: column;
	flex-basis: 300px;
}

.about-image-container {
	width: 370px;
}

.about-image-wrapper {
	display: inline-block;
	overflow: hidden;
	width: 100%;
	border-radius: 10%;
	transform: rotate(3deg);
}

.about-image-wrapper img {
	display: block;
	width: 100%;
}

.about-socials {
	padding-top: 50px;
	padding-bottom: 20px;
	/* text-align: center; */
	display: flex;
	justify-content: space-evenly;
}

.about-socials-mobile {
	display: none;
}

.about-social-icon {
	/* padding-right: 30px; */
	font-size: 35px;
	transition: color 0.2s ease-in-out;
	color: var(--secondary-color);
	padding-bottom: 0px;
}

.about-social-icon:hover {
	color: var(--primary-color);
}

.about-social-icon-container {
	margin-bottom: 20px;
}

.about-download-link {
	text-decoration: none;
	color: var(--secondary-color);

	.about-download-text {
		text-decoration: underline;
	}
}

.about-download-link:hover {
	.about-download-icon {
		/* transition: color 0.01s; */
		color: var(--primary-color);
	}

	.about-download-text {
		/* font-weight: bolder; */
		color: var(--primary-color);
		/* text-decoration: underline; */
	}
}

.about-download-icon {
	/* padding-right: 30px; */
	font-size: 35px;
	/* transition: color 0.2s ease-in-out; */
	color: var(--secondary-color);
	padding-bottom: 0px;
	padding-top: 35px;
	padding-right: 15px;
}

.about-download-icon:hover {
	color: var(--primary-color);
}

/* .about-social-icon-container:hover {
	color: var(--primary-color);
} */

.download-card {
	mix-blend-mode: normal;
	border-radius: 20px;
	/* opacity: 0.8; */
	padding: 20px;
}

.download-card a {
	text-decoration: none;
}

.download-card-icon {
	font-size: 25px;
	color: #27272a;
	/* padding-right: 10px; */
	text-align: center;
	/* padding-top: 15px; */
	padding-left: 10px;
	padding-right: 10px;
}

.download-card-title {
	font-family: var(--tertiary-font);
	color: var(--primary-color);
	font-size: 18px;
	padding-top: 15px;
	padding-bottom: 1em;
	font-weight: 700;
}

.download-card-container {
	/* width: 285px; */
	box-sizing: border-box;
	/* padding: 20px; */
}

.download-card:hover {
	background: #f7f7f7;
	opacity: 1;
	transition: background-color 0.3s ease-in-out;
}

.download-card:hover .download-card-link {
	color: var(--link-color);
	transition: color 0.3s ease-in-out;
}

.download-title-icon-container {
	display: flex;
	justify-content: center;
	align-items: center;
	padding-bottom: 0.5em;
	letter-spacing: -1px;
	align-self: flex-end;
}

.subtitle-dLink-container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.download-card-link {
	display: flex;
	align-items: center;
	color: var(--secondary-color);
	font-size: 12px;
	padding-top: 8px;
}

.download-card-link-text {
	/* padding-left: 20px; */
	font-weight: 700;
}

div#downloadCard {
	display: none;
}

@media (320px <= width <= 768px) {
	.about-logo {
		display: none;
	}

	.about-container {
		margin-top: 90px;
	}

	div.about-title {
		width: 100%;
		text-align: center;
		padding-top: 20px;
	}

	div.subtitle-dLink-container {
		display: flex;
		flex-direction: column;
	}

	div.about-subtitle {
		width: 100%;
		text-align: center;
		margin-top: 30px;
	}

	div.about-download-icon-container {
		display: none;
	}

	div#downloadCard {
		display: flex;
		justify-content: center;

		div.home-card {
			height: 70px;
			width: 300px;
			margin-bottom: 20px;
			margin-top: 40px;
		}

		div.home-card-container {
			height: 100%;
			width: 100%;
			padding: 0px;
		}

		div.home-title-icon-container {
			display: flex;
			align-items: center;
			justify-content: space-around;
			padding-bottom: 0px;
			padding-top: 7px;
		}

		div.home-card-description {
			padding: 0px;
		}

		div.home-card-title {
			letter-spacing: 0.05em;
			font-size: 18px;
			font-family: system-ui, -apple-system, BlinkMacSystemFont,
				"Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
				"Helvetica Neue", sans-serif;
		}
	}

	svg.about-social-icon {
		font-size: 2.8em;
	}
}

@media (414px < width <= 768px) {
	div.about-title {
		font-size: 55px;
	}

	div.about-subtitle {
		font-size: 20px;
	}

	div#downloadCard {
		div.home-card-title {
			font-size: 20px;
		}
	}
}
@media (768px < width <= 800px) {
	div.about-subtitle {
		width: 80%;
	}
}

@media (768px <= width <= 1600px) {
	.about-logo {
		display: none;
	}
}

@media (600px < width) {
}
