@import "../../../data/styles.css";

.nav-container {
	margin: 0;
	display: flex;
	justify-content: center;
	align-items: center;
}

.navbar {
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: fixed;
	top: 3vh;
	z-index: 999;
	font-family: var(--primary-font);
}

.nav-background {
	width: 15vw;
	/* width: 180px; */
	height: 40px;
	padding-left: 0%;
	padding-right: 0%;
	/* background: #fff; */
	background: var(--nav-bg-color);
	box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.3);
	border-radius: 40px;
}

.nav-list {
	display: flex;
	justify-content: space-between;
	list-style: none;
	margin-left: -16px;
	margin-right: 20px;
	margin-top: 11px;
}

.nav-item {
	font-weight: bold;
	font-size: 80%;
}

.nav-item.active a {
	color: var(--link-color);
}

.nav-item a {
	text-decoration: none;
	color: var(--primary-color);
}

.nav-item a:hover {
	color: var(--link-color);
	transition: color 0.3s ease-in-out;
}

.nav-item-icon {
	display: none;
}

@media (320px <= width <= 768px) {
	.nav-item-title {
		display: none;
	}

	.nav-item-icon {
		display: flex;
		font-size: 2.5em;
	}

	.nav-item-icon svg {
		color: var(--secondary-color);
	}

	.nav-item-icon.active svg {
		color: var(--link-color) !important;
	}

	.nav-item-icon svg {
		padding-left: 0px;
	}

	.nav-list {
		width: 100%;
		display: flex;
		padding-left: 0px;
		padding-top: 4px;
		align-items: center;
		margin-left: 0px;
		margin-right: 0px;
		justify-content: space-around;
	}

	.navbar {
		margin-left: 25%;
		margin-right: 25%;
		width: 80%;
		font-size: 80%;
	}

	.nav-background {
		width: 80%;
		height: 55px;
	}
}

@media (768px <= width <= 1280px) {
	.nav-item {
		display: flex;
		font-size: 1.4em;
	}

	.nav-list {
		width: 100%;
		display: flex;
		padding-left: 0px;
		padding-top: 4px;
		align-items: center;
		margin-left: 0px;
		margin-right: 0px;
		justify-content: space-around;
	}

	.navbar {
		margin-left: 25%;
		margin-right: 25%;
		width: 80%;
		font-size: 80%;
	}

	.nav-background {
		width: 80%;
		height: 55px;
	}
}

@media (1280px <= width <= 1600px) {
	.navbar {
		margin-left: 25%;
		margin-right: 25%;
		width: 50%;
		font-size: 80%;
	}

	.nav-background {
		width: 80%;
		height: 55px;
	}

	.nav-list {
		padding-top: 5px;
	}

	.nav-item {
		display: flex;
		font-size: 1.4em;
		padding-left: 5px;
		padding-right: 5px;
	}
}

@media (min-width: 1600px) {
	.navbar {
		width: 20vw;
	}
}
